import React, { useState, useEffect } from 'react';
import SimpleTable from './components/SimpleTable';
import './App.css';

function App() {



  return (
    <div className="App">
      <header className="App-header">
        Moin
      </header>
      <SimpleTable />
    </div>
  );
}

export default App;
